<template>
  <div class="container-fluid my-3">
    <b-skeleton-wrapper :loading="ProdByTagLoading">
      <template #loading>
        <b-container fluid>
          <landingLoader></landingLoader>
        </b-container>
      </template>
      <b-row>
        <b-col
          sm="3"
          v-if="!ProdByTagLoading && items != null && items.length > 0"
        >
          <SidePage
            @Change-Search="ChangeSearch"
            :max="maxProdPrice"
          ></SidePage>
        </b-col>
        <b-col sm="9">
          <div
            v-if="!ProdByTagLoading && items != null"
            class="ProdList container"
          >
            <b-row>
              <b-col
                lg="3"
                cols="6"
                class="px-1 my-1"
                v-for="Product in itemsForList"
                v-bind:key="Product.id"
              >
                <ProdCard
                  class="ProdByCatItem"
                  v-bind:Product="Product"
                ></ProdCard>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-pagination
                v-if="!ProdByTagLoading && items.length > 0"
                class="my-3 p-0"
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                aria-controls="my-cards"
              ></b-pagination>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="ProdByTagLayout.tag == null || items.length == 0"
        align-h="center"
      >
        <EmptyPage />
      </b-row>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import landingLoader from "../components/widgets/landingLoader.vue";
import ProdCard from "../components/items/ProdCard.vue";
import SidePage from "../components/widgets/SidePage.vue";
import EmptyPage from "../components/widgets/EmptyPage.vue";
export default {
  name: "ProdByTag",
  metaInfo: {
    title: "Tag-Products",
  },
  computed: {
    ...mapGetters(["Categories", "ProdByTagLayout"]),
    itemsForList() {
      if (this.items != null) {
        return this.items.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
      } else return [];
    },
  },
  components: {
    ProdCard,
    landingLoader,
    SidePage,
    EmptyPage,
  },
  methods: {
    ...mapActions(["getProdByTag"]),
    getResults(page = 1) {
      var ProdByTag = this.$route.params.ProdByTag;
      var obj = {
        tag: ProdByTag,
        breakpoint: this.$mq,
        Where: "ProdByTag",
        page: page,
        filter: this.searchform,
      };
      this.getProdByTag(obj);
    },
    ChangeSearch(sform) {
      this.searchform = sform;
      this.getResults();
    },
  },
  mounted() {
    var tagId = this.$route.params.ProdByTag;
    var message = "Products By Tag " + tagId + " Page";
    this.$gtag.event(message, { method: "Google" });
    this.getResults();
  },
  data() {
    return {
      items: [],
      perPage: 8,
      currentPage: 1,
      ProdByTagLoading: true,
      searchform: {
        minprice: 0.1,
        maxprice: 2000,
        highRate: 5,
        lowRate: 0,
        limit: 20,
        sort: "DESC",
        condition: "New",
      },
      maxProdPrice: 0,
    };
  },
  watch: {
    currentPage() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 300);
    },
    ProdByTagLayout(newValue) {
      if (newValue) {
        this.items = newValue.tag;
        this.ProdByTagLoading = false;
        this.maxProdPrice = newValue.maxPrice;
      }
    },
    "$route.params.ProdByTag"() {
      this.ProdByTagLoading = true;
      var obj = {
        tag: this.$route.params.ProdByTag,
        breakpoint: this.$mq,
        Where: "ProdByTag",
        filter: this.searchform,
      };
      this.currentPage = 1;
      return this.getProdByTag(obj);
    },
  },
};
</script>

<style scope></style>
