<template>
  <div class="bg-white p-5 mb-4">
    <h3 v-if="isOffers" class="text-primary text-center">
                لا يوجد عروض خاصة على المنتجات في الوقت الحالي

    </h3>
    <h3 v-else class="text-primary text-center">
      {{ $t("noProd") }} 
    </h3>

    <b-row align-h="center">
      <b-button
        variant="outline-secondary"
        class="py-1 mt-3 font-weight-bold"
        :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
        :to="{ name: 'Home' }"
        >{{ $t("Main") }}</b-button
      >
    </b-row>
  </div>
</template>

<script>
export default {
  computed:{
    isOffers(){
 
      if(this.$route.params.ProdByTag=='1138') return true
      else return false
    }
  }
};
</script>

<style>
</style>